<template>
  <v-autocomplete
    label="Référence"
    outlined
    hide-details
    dense
    :value="value"
    :filter="customFilter"
    :items="partNumberList"
    :loading="loading"
    return-object
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        color="primary"
        class="my-2 white--text"
        v-on="on"
      >
        <span v-text="item.name" />
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.name" />
        <v-list-item-subtitle v-text="item.partType.name" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import Repositories from 'src/repositories'
import { mapActions } from 'vuex'

export default {
  name: 'PartNumberSelection',
  components: { },
  props: {
    value: {
      type: [Array, String, Object],
      default: null,
    },
  },
  data: () => ({
    partNumberList: [],
    loading: false,
  }),
  async mounted () {
    try {
      this.loading = true
      this.partNumberList = await Repositories.partNumber.getList()
    }
    catch (error) {
      this.showErrorNotification(Repositories.parseErrorMessage(error))
    }
    finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    customFilter (item, queryText) {
      const lowerQueryText = queryText.toLowerCase()
      const partNumber = item.name.toLowerCase()
      const partType = item.partType.name.toLowerCase()

      return partNumber.indexOf(lowerQueryText) > -1
          || partType.indexOf(lowerQueryText) > -1
    },
  },
}
</script>
<style scoped>
.v-avatar v-list-item__avatar {
  width: 52px !important;
}
</style>
