<template>
  <v-data-table
    :value="selected"
    :headers="headers"
    :items="items"
    :items-per-page="10"
    :search="search"
    :loading="loading"
    :loading-text="'Chargement en cours...'"
    :hide-default-footer="!!$slots.footer"
    show-select
    item-key="_id"
    sort-by="serialNumber"
    :item-class="getItemClass"
    multi-sort
    @input="$emit('update:selected', $event)"
  >
    <template v-slot:[`item.rejected`]="{ item }">
      <v-simple-checkbox
        :value="item.rejected"
        disabled
      />
    </template>
    <template v-if="$slots.footer" v-slot:footer="{ props, on }">
      <v-row
        align="center"
        no-gutters
        class="px-2"
        style="border-top: thin solid rgba(0, 0, 0, 0.12)"
      >
        <v-col cols="auto">
          <slot name="footer" />
        </v-col>
        <v-col>
          <v-data-footer v-bind="props" style="border: none" v-on="on" />
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'PartList',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    selected: {
      type: Array,
      default: () => ([]),
    },
    search: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headers () {
      return [
        {
          text: 'Numéro de série',
          align: 'center',
          value: 'serialNumber',
        },
        {
          text: 'Référence',
          align: 'center',
          value: 'partNumber.name',
        },
        {
          text: 'Type de référence',
          align: 'center',
          value: 'partType.name',
        },
        {
          text: 'Numéro d\'OF',
          align: 'center',
          value: 'batch.number',
        },
        {
          text: 'Rebuté',
          align: 'center',
          value: 'rejected',
        },
      ]
    },
    items () {
      return this.value.map(item => ({
        ...item,
        isSelectable: !item.rejected,
      }))
    },
  },
  methods: {
    getItemClass (item) {
      if (item.destroyed) return 'destroyed'
      if (item.rejected) return 'rejected'
      if (item.retouched) return 'retouched'
      if (item.exception) return 'exception'
      return ''
    },
  },
}
</script>
<style >
.exception{
  background-color: #fff7ae;
}
.retouched{
  background-color: #aed4ff;
}
.rejected{
  background-color: #ffaeae;
}
.destroyed{
  background-color: #b7b7b7;
}
</style>
