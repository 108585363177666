<template>
  <ag-modal-container
    :title="'Rebuter des pièces en stock'"
    width="500px"
    no-content-padding
    toolbar-color="error"
    @close="close"
  >
    <v-form ref="rejectionForm" v-model="mainFormValid" class="pa-6">
      <ag-autocomplete
        v-model="decision.cause"
        outlined
        label="Choix de la cause du rebut *"
        :item-list="rejectionCauseList"
        item-text="name"
        item-value="value"
        :rules="[v => !!v || 'Requis']"
      />
      <v-text-field
        v-if="decision.cause !== ''"
        v-model="decision.justification"
        hide-details="auto"
        outlined
        label="Commentaire"
      />
    </v-form>
    <template v-slot:footer>
      <v-spacer />
      <v-btn
        :disabled="!mainFormValid"
        color="accent"
        class="ml-5"
        @click="validate"
        v-text="'Valider'"
      />
      <v-spacer />
    </template>
  </ag-modal-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'

import AgModalContainer from 'src/components/common/AgModalContainer'
import AgAutocomplete from 'src/components/common/AgAutocomplete'

export default {
  name: 'RejectionSelection',
  components: {
    AgModalContainer,
    AgAutocomplete,
  },
  props: {
    partTypeId: {
      type: String,
      required: true,
    },
    operationTypeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    mainFormValid: false,
    decision: {
      cause: '',
      value: null,
    },
    rejectionCauseList: [],
  }),
  computed: {
    isValidForm () {
      return this.mainFormValid
    },
  },
  watch: {
    partTypeId: {
      immediate: true,
      async handler () {
        this.rejectionCauseList = await Repositories.rejectionCause.getRejectionListForOperation({
          operationTypeId: this.operationTypeId,
          partTypeId: this.partTypeId,
        })
      },
    },
    'decision.value': {
      immediate: false,
      async handler () {
        this.mainFormValid = this.$refs.rejectionForm.validate()
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    close () {
      this.$emit('close')
    },
    validate () {
      this.$emit('validation', this.decision)
    },
  },

}
</script>
