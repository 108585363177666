var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"label":"Référence","outlined":"","hide-details":"","dense":"","value":_vm.value,"filter":_vm.customFilter,"items":_vm.partNumberList,"loading":_vm.loading,"return-object":""},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"my-2 white--text",attrs:{"input-value":selected,"color":"primary"}},'v-chip',attr,false),on),[_c('span',{domProps:{"textContent":_vm._s(item.name)}})])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.partType.name)}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }